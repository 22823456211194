
import DocsTOC from './DocsTOC.vue'
import Documentation from './Documentation.vue'
import Vue from 'vue'
import bus, { DocsEventTypes } from './DocsEventBus'
import { routeQueue } from '../../router'
export default Vue.extend({
  components: { DocsTOC, Documentation },
  name: 'DocsSidebar',
  async created () {
    bus.$on(DocsEventTypes.open, this.openDoc)
    bus.$on(DocsEventTypes.close, () => {
      this.isOpen = false
    })
    const docs = (await import('./docs')).default
    this.names = docs.names
  },
  destroyed () {
    bus.$off(DocsEventTypes.open)
    bus.$off(DocsEventTypes.close)
  },
  data () {
    return {
      isOpen: false,
      isTOCOpen: false,
      currentFile: '',
      names: [],
    }
  },
  computed: {
    width (): number {
      // TODO: Handle various screen widths
      return 400
    },
    newTabLink (): object {
      const route = routeQueue.resolve({
        name: 'Documentation',
        params: {
          filePath: this.currentFile.slice(2),
        },
      })
      return route.href
    },
  },
  methods: {
    openDoc (fileKey?: string) {
      console.log('opening doc', fileKey)
      // TODO: Check if the fileKey is an exact match. If it isn't find the closest match.
      this.isOpen = true
      if (fileKey) {
        if (fileKey.startsWith('./')) {
          fileKey = fileKey.substring(2)
        } else if (fileKey.startsWith('/')) {
          fileKey = fileKey.substring(1)
        }
        this.currentFile = fileKey
        this.isTOCOpen = false
      } else {
        this.isTOCOpen = true
      }
    },
    openTOC () {
      this.isTOCOpen = true
      this.isOpen = false
    },
    closeTOC () {
      this.isTOCOpen = false
      this.isOpen = true
    },
  },
})
