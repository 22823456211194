
import Vue, { Component } from 'vue'

export default Vue.extend({
  name: 'Documentation',
  props: {
    currentFile: {
      type: String,
      required: true,
    },
    transformLinks: {
      type: Boolean,
      default: false,
    },
    preventLinkPropagation: {
      type: Boolean,
      default: false,
    },
  },
  async created () {
    this.isLoaded = false
    const docs = (await import('./docs')).default
    console.log('docs', docs)
    this.content = docs.content
    this.isLoaded = true
  },
  data () {
    return {
      isLoaded: false,
      content: {},
    }
  },
  computed: {
    parts (): string[] {
      return this.currentFile.split('#')
    },
    target (): string|null {
      return this.parts.length > 1 ? this.parts[1] : null
    },
    fileKey (): string {
      const fileName = this.parts[0]
      return fileName.slice(-3) === '.md' ? fileName : fileName + '.md'
    },
    markdown (): Component {
      console.log('markdown')
      console.log('Current documentation file', this.currentFile, this.content)
      console.log('key', this.fileKey, 'target', this.target)
      if (this.isReady && this.content[this.fileKey]) {
        return this.content[this.fileKey]
      } else {
        return undefined
      }
    },
    isReady (): boolean {
      return this.isLoaded && !!this.currentFile && !!this.content
    },
  },
})
