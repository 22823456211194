
import Vue from 'vue'
import ModalTitle from './ModalTitle.vue'
import ScrollContainer from './styles/ScrollContainer.vue'
export default Vue.extend({
  name: 'TrellisModal',
  components: { ModalTitle, ScrollContainer },
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose ($event: any) {
      // @ts-ignore
      this.$refs.dialog.onClickOutside($event)
      this.$emit('close')
      this.$emit('input', false)
    },
    onInput ($event: any) {
      // this.$emit('input', v)
      if (!$event) {
        this.onClose($event)
      }
      this.$emit('input', $event)
    },
  },
})
