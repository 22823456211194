
  import Vue from 'vue'
  export default Vue.extend({
    props: {
      title: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    name: 'ModalTitle'
  })
