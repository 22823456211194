
  import Vue from 'vue'
  import IsLoggedInMixin from '../mixins/IsLoggedInMixin'
  import PlatformMixin from '../mixins/PlatformMixin'

  export default Vue.extend({
    name: 'Banner',
    mixins: [IsLoggedInMixin, PlatformMixin],
    props: {
      serverMode: String
    },
    computed: {
      backgroundColor () {
        return this.serverMode === 'test' ? 'yellow' : 'orange'
      }
    }
  })
