
  import Vue from 'vue'
  import global from '../static/singleton'
  export default Vue.extend({
    name: 'TrellisLoadingCircle',
    data () {
      return {
        global
      }
    },
    props: {
      color: {
        type: String,
        default: 'primary'
      },
      size: {
        type: String,
        default: '50px'
      },
      thickness: {
        type: Number,
        default: 4
      },
      padding: {
        type: String,
        default: '0'
      },
      margin: {
        type: String,
        default: '0'
      }
    },
    computed: {
      borderColor () {
        if (this.$vuetify && this.$vuetify.theme) {
          const theme = this.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark : this.$vuetify.theme.themes.light
          return theme[this.color]
        }
      },
      containerStyles (): object {
        return {
          width: this.size,
          height: this.size,
          padding: this.padding,
          margin: this.margin
        }
      },
      circleStyles (): object {
        return {
          width: this.size,
          height: this.size,
          borderWidth: this.thickness,
          borderTopColor: this.borderColor,
          borderLeftColor: this.borderColor
        }
      }
    }
  })
