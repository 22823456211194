import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=039beb48&scoped=true"
import script from "./MainMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./MainMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MainMenu.vue?vue&type=style&index=0&id=039beb48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039beb48",
  null
  
)

export default component.exports