
  import PermissionMixin from '../../mixins/PermissionMixin'
  import global from '../../static/singleton'
  import User from '../../entities/trellis/User'
  import UserService from '../../services/user'
  import PasswordField from './PasswordField.vue'
  import Vue from 'vue'

  export default Vue.extend({
    name: 'UserPassword',
    components: { PasswordField },
    mixins: [PermissionMixin],
    props: {
      user: Object as () => User
    },
    data () {
      return {
        global,
        formValid: false,
        showOldPass: false,
        showNewPass: false,
        oldPassword: '',
        newPassword: '',
        oldPassRules: [
          // @ts-ignore
          () => (!!this.oldPassword || this.$t('empty_value'))
        ]
      }
    },
    methods: {
      async save () {
        // @ts-ignore
        if (this.$refs.form.validate()) {
          try {
            const res = await UserService.updatePassword(this.user, this.oldPassword, this.newPassword)
            const msg = this.$t('password_updated')
            this.alert('success', msg)
            this.$emit('done')
          } catch (err) {
            this.alert('error', `Invalid password or permissions`, {timeout: 0})
          }
        }
      }
    }
  })
