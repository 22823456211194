
import Vue from 'vue'

export default Vue.extend({
  name: 'ScrollContainer',
  props: {
    elevation: {
      type: Number,
      default: 4,
    },
    containerClass: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isScrolled: false,
    }
  },
  mounted () {
    (this.$refs.scroller as Element).addEventListener('scroll', this.onScroll, { passive: true })
    this.onScroll()
  },
  methods: {
    onScroll () {
      const el = this.$refs.scroller as Element
      this.isScrolled = el.scrollTop > 0
    },
  },
  computed: {
    attrs () {
      return 
    }
  }
})
