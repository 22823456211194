
  import Vue from 'vue'
  import TrellisLoadingCircle from '../TrellisLoadingCircle.vue'
  export default Vue.extend({
    name: 'LoginForm',
    components: { TrellisLoadingCircle },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      isWorking: {
        type: Boolean,
        default: false
      },
      showLoginButton: {
        type: Boolean,
        default: true
      },
      clearCredentials: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isValid: this.value,
        isPassHidden: true,
        rules: {
          username: [
            v => !!v || this.$t('required_field')
          ],
          password: [
            v => !!v || this.$t('required_field')
          ]
        },
        username: '',
        password: ''
      }
    },
    watch: {
      isValid (newVal) {
        this.$emit('input', newVal)
      }
    },
    methods: {
      login () {
        if (this.isValid && this.$refs.form.validate()) {
          this.$emit('login', this.username.trim(), this.password.trim())
          if (this.clearCredentials) {
            this.username = ''
            this.password = ''
          }
        }
      },
      togglePassHidden () {
        this.isPassHidden = !this.isPassHidden
        if (this.$refs.passField) {
          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.passField.focus()
          })
        }
      }
    }
  })
