
  import GeoLocationService from '../services/geolocation/index'
  import { FakePosition } from '../services/geolocation/GeoLocationAbstract'

  const PositionError = {
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3
  }

  const data = {
    isOpen: false,
    lastKnownCoordinates: null,
    lastKnownTime: 0,
    attempts: 0,
    maxAttempts: 3,
    showSkip: false,
    isResolved: false,
    state: 'detecting',
    tolerance: 5 * 60 * 1000,
    skipAvailableDelay: 3000,
    dialogCloseDelay: 3 * 1000
  }

  let vueInstance

  export function getCurrentPosition () {
    data.isOpen = true
    data.attempts = 0
    data.state = 'detecting'
    return new Promise((resolve, reject) => {
      if (!vueInstance) reject(new Error(`Location finder needs to be in the DOM already`))
      vueInstance.tryForPosition(resolve, reject)
    }).then(coords => {
      data.isOpen = false
      return coords
    })
  }

  export default {
    name: 'LocationFinder',
    data () {
      return data
    },
    beforeCreate () {
      vueInstance = this
    },
    beforeDestroy () {
      vueInstance = null
    },
    methods: {
      tryForPosition (resolve, reject) {
        this.isResolved = false
        this.resolver = resolve
        this.reject = reject
        this.retry()
      },
      resolve (...args) {
        if (!this.isResolved) {
          this.resolver.apply(null, args)
          this.isResolved = true
        }
      },
      skip () {
        this.resolve(null)
      },
      async useLastPosition () {
        const lastPos = await GeoLocationService.getLatestPosition()
        this.resolve(lastPos.coords)
      },
      async retry () {
        this.state = 'detecting'
        try {
          const pos = await this.getPosition()
          this.state = 'found-location'
          setTimeout(() => {
            this.resolve(pos.coords)
          }, this.dialogCloseDelay)
        } catch (err) {
          this.log(err)
          if (err.code === PositionError.PERMISSION_DENIED) {
            this.state = 'permission-denied'
            console.error('Permission denied')
            setTimeout(() => {
              this.resolve(null)
            }, this.dialogCloseDelay)
          } else if (this.attempts >= this.maxAttempts) {
            this.state = 'exceeded-max'
            setTimeout(() => {
              console.error('Too many attempts')
              this.resolve(null)
            }, this.dialogCloseDelay)
          } else if (err.code === PositionError.TIMEOUT) {
            this.state = 'timeout'
          } else {
            this.state = 'position-unavailable'
          }
        }
      },
      async getPosition () {
        this.showSkip = false
        this.attempts++
        const tol = 5 * 60 * 1000
        setTimeout(() => {
          this.showSkip = true
        }, this.skipAvailableDelay)
        try {
          const pos = await GeoLocationService.getLocationTolerance(tol)
          this.lastKnownCoordinates = pos.coords
          this.lastKnownTime = new Date(pos.timestamp)
          return pos
        } catch (err) {
          if (err && err.message && err.message.toLowerCase().startsWith('only secure origins are allowed')) {
            // necessary for dev over http
            this.lastKnownCoordinates = FakePosition.coords
            this.lastKnownTime = new Date(pos.timestamp)
            return FakePosition
          }
          throw err
        }
      }
    }
  }
