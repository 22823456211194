
  import Vue from 'vue'
  import maintenanceService from '../services/maintenance'

  export default Vue.extend({
    name: 'Maintenance',
    props: {
      value: Boolean,
    },
    data () {
      return {
        status: {
          active: this.value,
        },
        intervalId: null,
      }
    },
    async created () {
      this.status = await maintenanceService.getStatus()
      if (this.status.active) {
        this.intervalId = setInterval(this.check, 5 * 60 * 1000)
      }
    },
    beforeDestroy () {
      clearInterval(this.intervalId)
    },
    methods: {
      async check() {
        this.status = await maintenanceService.getStatus()
        if (!this.status.active) {
          this.$emit('input', false)
          clearInterval(this.intervalId)
        }
      },
    }
  })
