
import Vue, { PropOptions } from 'vue'
import TrellisModal from '../TrellisModal.vue'
import formatBytes from '../../filters/format-bytes.filter'

enum FileType {
  Image = 'image/*',
  Video = 'video/*',
  Audio = 'audio/*',
  Text = 'text/*',
  Application = 'application/*',
}

type UFile = File & { status: 'uploaded' | 'uploading' | 'failed', error: any, response: any }

export default Vue.extend({
  name: 'TrellisFileUpload',
  components: { TrellisModal },
  filters: { formatBytes },
  props: {
    title: String,
    uploadFile: Function,
    types: {
      type: Array,
      required: false,
    } as PropOptions<FileType[]>,
    extensions: {
      type: Array,
      required: false,
    } as PropOptions<string[]>,
    multiple: {
      type: Boolean,
      default: false,
    },
    axiosResponse: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      files: [] as UFile[],
      isInvalid: false,
      isDragging: false,
      isUploading: false,
      visibleError: null,
      visibleResponse: null,
    }
  },
  methods: {
    async upload () {
      this.isUploading = true
      for (const file of this.files) {
        if (this.isUploading && file.status !== 'uploaded') {
          await this.uploadOne(file)
        }
      }
      this.isUploading = false
      this.$emit('upload-done')
    },
    async uploadOne (file: UFile) {
      try {
        file.status = 'uploading'
        this.$forceUpdate()
        file.response = await this.uploadFile(file)
        file.status = 'uploaded'
      } catch (err) {
        console.error(err)
        console.dir(err)
        file.status = 'failed'
        file.error = err
      }
      this.$forceUpdate()
    },
    cancel () {
      this.isUploading = false
    },
    dragover (event: Event) {
      // @ts-ignore
      const files: File[] = event.dataTransfer.files
      for (const file of files) {
        if (!this.canAcceptFile(file)) {
          this.isInvalid = true
          break
        }
      }
    },
    dragleave () {
      this.isInvalid = false
    },
    onDrop (event: Event) {
      if (this.isUploading || this.isInvalid) {
        return
      }
      this.isDragging = false
      // @ts-ignore
      const files: File[] = event.dataTransfer.files
      if (!this.multiple && files.length > 1) {
        this.alert('warning', 'Cannot upload multiple files')
        return
      }
      for (const file of files) {
        if (this.canAcceptFile(file)) {
          this.files.push(file as UFile)
        }
      }
    },
    onChange (event: Event) {
      // @ts-ignore
      const files: File[] = this.$refs.fileInput.files
      for (const file of files) {
        this.files.push(file as UFile)
      }
    },
    onClose () {
      this.files = []
      this.$emit('close')
    },
    removeFile (file: UFile) {
      const index = this.files.indexOf(file)
      this.files.splice(index, 1)
    },
    canAcceptFile (file: File): boolean {
      if (!this.extensions || this.extensions.length) {
        return true
      }
      for (const ext of this.extensions) {
        if (file.name.endsWith(ext)) {
          return true
        }
      }
      return false
    },
  },
  computed: {
    accept (): string {
      let res = ''
      if (this.types) {
        res = this.types.join(',')
      }
      if (this.extensions) {
        res += (res ? ',' : '') + this.extensions.map(e => '.' + e).join(',')
      }
      return res
    },
  },
})
