export default [
  'Login',
  'Documentation',
  'Info',
  'DemoSignUp',
  'Logs',
  'Storage',
  'Changelog',
  'LocationHistory',
  'EmailConfirmation',
  'Sync',
  'Home',
  'RegisterDevice',
  'ConfigureServer',
]
