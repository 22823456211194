
  import Log from '../entities/trellis-config/Log'
  /**
   * A component for displaying info/errors/warnings in a friendly format with the
   * ability to view the full error and perhaps copy or share the error message
   */
  export default {
    name: 'trellis-alert',
    data () {
      return {
        showMore: false
      }
    },
    props: {
      currentLog: {
        required: true,
        validator: (value) => { return (value === undefined || value instanceof Log) }
      },
      show: {
        required: false,
        'default': true,
        type: Boolean
      }
    },
    methods: {
      getMessage () {
        if (this.currentLog instanceof Log) {
          return this.currentLog.message
        }
        return ''
      },
      getFullMessage () {
        if (this.currentLog instanceof Log) {
          if (typeof this.currentLog.fullMessage === 'string') {
            return this.currentLog.fullMessage.replace(/\\n/g, '\n')
          }
          return this.currentLog.fullMessage
        }
        return ''
      },
      getSeverity () {
        if (this.currentLog instanceof Log) {
          return this.currentLog.severity
        }
        return ''
      },
      toggleShow () {
        if (this.isMore) {
          this.showMore = !this.showMore
        }
      },
      selectAll (event) {
        this.$refs.textarea.select()
      },
      getType () {
        const curSeverity = this.getSeverity()
        if (curSeverity === 'error') {
          return 'error'
        }
        if (curSeverity === 'warn') {
          return 'warning'
        }
        return 'info'
      }
    },
    computed: {
      isMore: function () {
        return (this.currentLog instanceof Log && this.currentLog.fullMessage !== null)
      }
    },
    components: {
    }
  }
