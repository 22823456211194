
import Vue from 'vue'
import { DocsEventTypes } from './DocsEventBus'
export default Vue.extend({
  async created () {
    const d = await import(/* webpackChunkName: "documentation" */'./docs')
    const docs = d.default
    this.content = docs.content[this.fileName]
  },
  data () {
    return {
      fileName: '_Sidebar.md',
      content: null,
    }
  },
  name: 'DocsTOC',
  props: {
    transformLinks: {
      type: Boolean,
      default: false,
    },
    preventLinkPropagation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close () {
      this.$emit(DocsEventTypes.close)
    },
  },
})
