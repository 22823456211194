
import Vue from 'vue'
import config from '../config'

export default Vue.extend({
  name: 'debug',
  props: {
    name: {
      type: String,
      default: 'Debug'
    }
  },
  data: function () {
    return {
      isOpen: true,
    }
  },
  computed: {
    isInDebugMode: function () {
      return config.debug
    }
  }
})

