import { render, staticRenderFns } from "./RespondentLocationReport.md?vue&type=template&id=68e80ae0"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports