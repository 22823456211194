export enum TrellisPermission {
  REMOVE_RESPONDENT,
  ADD_RESPONDENT,
  ADD_OTHER_RESPONDENT,
  CHANGE_RESPONDENT_GEO_CURRENT,
  IMPORT_RESPONDENTS,
  ADD_RESPONDENT_NAME,
  EDIT_RESPONDENT_NAME,
  REMOVE_RESPONDENT_NAME,
  ADD_RESPONDENT_GEO,
  REMOVE_RESPONDENT_GEO,
  MOVE_RESPONDENT,
  ADD_RESPONDENT_CONDITION_TAG,
  REMOVE_RESPONDENT_CONDITION_TAG,
  VIEW_RESPONDENTS,
  ADD_RESPONDENT_PHOTO,
  EDIT_RESPONDENT_PHOTO,
  REMOVE_RESPONDENT_PHOTO,
  ADD_USER,
  REMOVE_USER,
  EDIT_USER,
  EDIT_GEO,
  ADD_GEO,
  REMOVE_GEO,
  ADD_GEO_PHOTO,
  REMOVE_GEO_PHOTO,
  ADD_GEO_TYPE,
  EDIT_GEO_TYPE,
  REMOVE_GEO_TYPE,
  ADD_FORM,
  EDIT_FORM,
  REMOVE_FORM,
  VIEW_CONFIG,
  EDIT_CONFIG,
  EDIT_TRANSLATION,
  REMOVE_TRANSLATION,
  VIEW_PERMISSIONS,
  EDIT_PERMISSIONS,
  VIEW_STUDIES,
  ADD_STUDY,
  EDIT_STUDY,
  REMOVE_STUDY,
  VIEW_USERS,
  VIEW_DEVICES,
  ADD_DEVICE,
  EDIT_DEVICE,
  REMOVE_DEVICE,
  EDIT_PASSWORDS,
  EDIT_LOCALE,
  ADD_LOCALE,
  VIEW_LOCALES,
  REMOVE_LOCALE,
  VIEW_SYNC,
  PROCESS_UPLOADS,
  ADD_SNAPSHOT,
  CAN_DOWNLOAD,
  CAN_UPLOAD,
  VIEW_REPORTS
}

export enum TrellisRole {
  ADMIN = 'ADMIN',
  SUPERVISOR = 'SUPERVISOR',
  SURVEYOR = 'SURVEYOR'
}

export type PermissionMap = {
  [key in TrellisPermission]: boolean
}

